
// @import './assets/owl.carousel.css';
// @import './assets/owl.theme.default.min.css';

// TODO: move to link
// @import './assets/css/fontawesome.min.css';

@import './components/global/index.scss';

.owl-nav.disabled {
    display: none;
}

// глобальные компоненты
@import './components/button/styles.scss';
@import './components/button/button.scss';
@import './components/hero-base/styles.scss';
@import './components/top-bar/styles.scss';
@import './components/slider/slider.scss';
@import './components/footer/footer.scss';
@import './components/phone/phone.scss';
@import './components/tab/tab.scss';
@import './components/contact/contact.scss';
@import './components/hero-big-image/index.scss';
@import './components/section-title/index.scss';

// секциии страницы product
@import './pages/product/hero-product/hero.scss';
@import './pages/product/how-work/how-work.scss';
@import './pages/product/bet-sys/bet-sys.scss';
@import './pages/product/features/features.scss';
@import './pages/product/integration/integration.scss';
@import './pages/product/styles.scss';

//секции страницы index (home)
// @import './components/hero-home/hero-home.scss';
// @import './pages/index/hero-index/hero.scss';
@import './pages/index/about-us/about-us.scss';
@import './pages/index/our-product/our-product.scss';
@import './pages/index/our-team/our-team.scss';
@import './pages/index/key-partners/key-partners.scss';
@import './pages/index/styles.scss';

//секции страницы team
// @import './pages/team/hero-team/hero-team.scss';
@import './pages/team/team-numbers/team-numbers.scss';
@import './pages/team/team-founders/team-founders.scss';
@import './pages/team/team-head/team-head.scss';
@import './pages/team/team-staff/team-staff.scss';
@import './pages/team/styles.scss';

//секции страницы contact
// @import './pages/contacts/hero-contact/hero-contact.scss';
@import './pages/contacts/feedback/feedback.scss';
@import './pages/contacts/map/map.scss';
@import './pages/contacts/styles.scss';

//секции страницы demo
@import './pages/demo/guide/guide.scss';

// test page
@import './pages/__test/styles.scss';

@import './global-fix.scss';

// $noRems: true;

// .no-rem {
//     @import './components/global/index.scss';

//     // глобальные компоненты
//     @import './components/top-bar/top-bar.scss';
//     @import './components/slider/slider.scss';
//     @import './components/footer/footer.scss';
//     @import './components/phone/phone.scss';
//     @import './components/button/button.scss';
//     @import './components/tab/tab.scss';
//     @import './components/contact/contact.scss';


//     // секциии страницы product
//     @import './pages/product/hero-product/hero.scss';
//     @import './pages/product/how-work/how-work.scss';
//     @import './pages/product/bet-sys/bet-sys.scss';
//     @import './pages/product/features/features.scss';
//     @import './pages/product/integration/integration.scss';

//     //секции страницы index (home)
//     // @import './components/hero-home/hero-home.scss';
//     @import './pages/index/hero-index/hero.scss';
//     @import './pages/index/about-us/about-us.scss';
//     @import './pages/index/our-product/our-product.scss';
//     @import './pages/index/our-team/our-team.scss';
//     @import './pages/index/key-partners/key-partners.scss';

//     //секции страницы team
//     @import './pages/team/hero-team/hero-team.scss';
//     @import './pages/team/team-numbers/team-numbers.scss';
//     @import './pages/team/team-founders/team-founders.scss';
//     @import './pages/team/team-head/team-head.scss';
//     @import './pages/team/team-staff/team-staff.scss';

//     //секции страницы contact
//     @import './pages/contacts/hero-contact/hero-contact.scss';
//     @import './pages/contacts/feedback/feedback.scss';
//     @import './pages/contacts/map/map.scss';

// }
