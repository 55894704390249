@import './components/global/constants.scss';

section {
    overflow: hidden;
}

.our-product__wrapper {
    // display: block;
}

.our-product__slider-pic {
    width: 100%;
}

.our-product__description-text {
    width: 100%;
}

.our-product__btn-learn-more {
    // margin: 0 auto;
}

.key-partners__partner-bage {
    outline: none;
}

@media (min-width: $desktop) {
    .our-product__wrapper {
        // display: flex;
    }

    .our-product__btn-learn-more {
        margin: 0;
    }

    .our-team__first-row {
        display: flex;
    }
}

@media (max-width: $mobile + 100px) {
    .our-team__first-row {
        display: block;
    }
    .our-team__person-bage {
        width: 100%;
    }
    .our-team__person-bage {
        margin-bottom: 20px;
    }
}

@media (max-width: $mobile) {
    .our-team__person-bage {
        width: 100%;
    }

    .our-team__first-row {
        .our-team__person-bage + .our-team__person-bage {
            margin-top: 16px;
        }
    }

    .our-team__second-row {
        display: block;
    }

    .our-team__person-bage {
        margin-bottom: 20px;
    }

    .key-partners__bages-wrapper {
        .key-partners__partner-bage {
            width: 100%;
            &:nth-child(2) {
                display: none;
            }
            &:nth-child(3) {
                display: none;
            }
            &:nth-child(4) {
                display: none;
            }
        }
    }
}
