.about-us {
    padding-top: v(48);
    padding-bottom: v(48);
    padding-left: v(72);
    padding-right: v(72);

    &__text {
        font-size: v(24);
        line-height: v(32);
        font-weight: bold;
        color: $white;

        /* Impact */
        font-family: $headerFontFamily;
        font-style: normal;
        font-weight: bold;
        font-size: v(28);
        line-height: v(32);
        text-align: left;

        /* Primary Dark 30 */
        color: $primary30;

        @media(min-width: $desktop) {
            margin: 0 auto;
            width: v(935);
        }
    }
}