.key-partners {
    padding-top: v(0.1);
    padding-bottom: v(24);

    &__subtitle {
        margin-top: v(36);
        margin-bottom: v(90);
        text-align: center;
        font-family: Alegreya Sans;
        font-style: normal;
        font-weight: bold;
        font-size: v(36);
        line-height: v(48);
        color: #fff;
        @media(min-width: 320px) and (max-width: 881px)  {
            font-size: v(20);
            line-height: v(32);
        }
        @media(min-width: 320px) and (max-width: 487px)  {
            margin-bottom: v(64);
        }
    }

    &__bages-wrapper-desktop {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-row-gap: v(48);
        @media(min-width: $desktop)  {
            grid-row-gap: v(96);
            grid-column-gap: v(24);
            margin-bottom: v(24);

        }
    }

    &__bages-wrapper {
        //display: flex;
        //justify-content: space-between;
        margin-bottom: v(40);
        .owl-item {
            display: inline-block;
        }
        @media(min-width: 883px)  {
            display: none;
        }
    }

    &__partner-bage {
        outline: 1px dashed gray;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__partner-bage-img {
        display: block;
        margin: 0 auto;
        width: v(72);
        //height: v(96);
        object-fit: contain;

        @media (min-width: $tablet) {
            width: v(120);
            //height: v(70);
        }

        @media (min-width: $desktop) {
            width: v(192);
            //height: v(96);
        }
    }

    &__partner-bage-name {
        font-size: v(16);
        line-height: v(24);
        text-align: center;
        color: $white;
        display: none;
    }

    &__slider-controls {
        display: flex;
        justify-content: space-between;
        width: v(144);
        height: v(24);
        margin-right: auto;
        margin-bottom: v(48);
        margin-left: auto;

        display: none;
    }

    &__slider-pin {
        display: block;
        width: v(8);
        height: v(8);
        border: 2px solid $accent50;
        border-radius: 50%;

        &--active {
            background-color: $accent50;
        }
    }
}

.key-partners__icelondon {
    transform: scale(1.3);
}
