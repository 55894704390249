.contact-us {
    padding-top: v(24);
    padding-bottom: v(56);
    position: relative;
    z-index: 1;

    &__lead {
        font-family: $headerFontFamily;
        font-style: normal;
        font-weight: bold;

        margin-bottom: v(42);
        font-weight: 600;
        text-align: center;
        color: white;

        // mobile
        font-size: v(24);
        line-height: v(32);

        @media (min-width: $tablet) {
            font-size: v(28);
            line-height: v(36);
        }
    }

    &__form {
        margin-bottom: v(40);

        @media (min-width: $desktop) {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &__inputs-block {
        position: relative;
        width: v(336);

        margin-left: auto;
        margin-right: auto;

        @media (min-width: $desktop) {
            display: flex;
            justify-content: space-between;
            width: v(840);
            margin-right: v(24);
            margin-left: 0;
        }

        .contact-us__btn-submit {
            transform: translateY(v(-3));
        }
    }

    &__input-wrap {
        position: relative;
        width: v(336);
    }

    &__input {
        position: relative;
        display: block;
        width: 100%;
        margin-bottom: v(32);
        padding: v(8) v(8) v(8) v(40);
        font-size: v(16);
        line-height: v(24);
        font-family: $textFontFamily;
        color: $primary30;
        background-color: $primary90a90;
        // border: 1px solid $primary40;
        border-radius: v(4);

        @media (min-width: $desktop) {
            margin-bottom: 0;
        }

        &::placeholder {
            color: $primary70;
        }

        &:focus {
            color: $white;
            border-color: $primary40;
        }

        &:placeholder-shown {
            border-color: $primary70;
        }
    }

    &__input-icon {
        position: absolute;
        top: 50%;
        left: v(12);
        width: v(18);
        height: v(18);
        transform: translateY(-60%);
        color: $primary40;
        font-size: v(18);
    }

    &__input:focus + .contact__input-icon {
        color: $primary40;
    }

    &__btn-submit {
        width: v(140);

        display: block !important;
        margin-right: auto;
        margin-left: auto;

        @media (min-width: $desktop) {
            margin: 0;
        }
    }

    &__end-text {
        font-weight: normal;
        font-size: v(24);
        line-height: v(24);
        font-family: $textFontFamily;
        text-align: center;
        color: $primary30;
    }

}

.contact-us-modal {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: rgba(0,0,0,0.1);
    z-index: 999;

    &.--closed {
        z-index: -1;
        display: none;
    }

    &__wnd {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        width: v(360);
        background: $primary100;
        border-radius: v(4);
        box-shadow: 0px v(4) v(8) rgba(0, 0, 0, 0.25);
    }

    &__close-btn {
        cursor: pointer;
        position: absolute;
        color: $accent50;
        font-size: v(24);
        top: v(10);
        right: v(12);
    }

    &__img {
        margin-top: v(24);
        margin-left: auto;
        margin-right: auto;
        display: block;
    }

    h2 {
        font-family: $headerFontFamily;
        font-weight: bold;
        font-size: v(24);
        text-align: center;
        color: $primary50;
        line-height: v(42);
    }

    p {
        font-family: $textFontFamily;
        font-size: v(16);
        line-height: v(24);
        text-align: center;
        color: $primary50;
        margin-left: v(24);
        margin-right: v(24);
        margin-bottom: v(18);
    }

    &__btn-ok {
        margin-bottom: v(24);
    }
}