.bet-sys {
    padding-top: v(0.1);
    padding-bottom: v(100);
    background-color: $primary100;
    position: relative;
    z-index: 1;
    @media(min-width: 320px) and (max-width: 881px)  {
        padding-bottom: v(64);
    }
    // background: linear-gradient(to bottom, $primary100, $primary90 80%);

    &__title {
        margin-bottom: v(24);

        @media (min-width: $desktop) {
            margin-bottom: 0;
        }
        @media(min-width: 320px) and (max-width: 487px)  {
            margin-bottom: v(64);
        }
    }

    &__subtitle {
        margin-top: v(64);
        margin-bottom: v(64);
        text-align: center;
        font-family: Alegreya Sans;
        font-style: normal;
        font-weight: bold;
        font-size: v(36);
        line-height: v(48);
        color: #fff;
        @media(min-width: 320px) and (max-width: 487px)  {
            margin-top: 0;
        }
        @media(min-width: 320px) and (max-width: 881px)  {
            margin-bottom: 0;
            font-size: v(20);
            line-height: v(32);
            margin-bottom: v(-40);
        }
    }

    &__container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        @media(min-width: 882px) and (max-width: 1175px)  {
            margin-top: v(100);
        }
        @media(min-width: 320px) and (max-width: 881px)  {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
        }
        @media(min-width: 1024px) and (max-width: 1175px)  {
            margin-top: v(100);
            margin-left: v(-100);
            margin-right: v(-100);
            margin-bottom: v(50);
        }
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        & img {
            width: v(350);
            height: v(280);
            @media(min-width: 488px) and (max-width: 881px)  {
                width: 50%;
                height: auto;
            }
            @media(min-width: 320px) and (max-width: 487px)  {
                width: 100%;
                height: auto;
            }
        }
        @media(min-width: 320px) and (max-width: 881px)  {
            margin-top: 64px;
        }
    }

    &__imgTitle {
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: v(20);
        line-height: v(24);
        text-align: center;
        color: #B8CCD9;
        margin-bottom: v(32);
    }



    &__imgText {
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: v(14);
        line-height: v(24);
        text-align: center;
        color: #B8CCD9;
        margin-top: v(32);
    }

    &__img {
        //justify-self: center;
        //height: 60%!important;
        //width: 75%!important;
        //margin: auto;
    }

    &__lead {
        margin-bottom: v(24);
        font-weight: 700;
        font-size: v(32);
        line-height: v(40);
        text-align: center;
        color: white;

        @media (min-width: $desktop) {
            font-size: v(36);
            line-height: v(48);
        }
    }

    &__img {
        //width: 100%;

        @media (min-width: $desktop) {
            display: block;
            width: v(522);
            height: v(336);
            margin-right: auto;
            margin-left: auto;
        }
    }

    &__img-correct {
        box-sizing: content-box;
        padding-left: v(2);
        //@media(min-width: 487px) and (max-width: 811px)  {
        //    padding-left: v(15);
        //
        //}
    }
}
