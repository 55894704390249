.phone {
    width: v(200);
    height: v(416);

    @media (min-width: $tablet) {
        width: v(208);
        height: v(424);
    }

    @media (min-width: $desktop) {
        width: v(231);
        height: v(471);
    }

    @media(min-width: $desktop) and (max-width: $desktop-full) {
        width: 225px;
        height: 423px;
    }

    @media(min-width: 812px) and (max-width: $desktop) {
        width: 147px;
        height: 273px;
    }
    @media(min-width: $tablet) and (max-width: 811px)  {
        width: 167px;
        height: 316px;
    }

    @media(min-width: 320px) and (max-width: $tablet)  {
        width: 127px;
        height: 240px;
    }

    &__cover {
        position: relative;
        z-index: 2;
        width: 100%;
        height: 100%;
        filter: drop-shadow(0px 4px 16px rgba(25, 42, 51, 0.6));
    }

    &__content {
        position: absolute;
        top: v(16);
        left: 50%;
        z-index: 1;
        height: 92%;
        overflow: hidden;
        transform: translateX(-50%);

        width: 82.5%;

        @media (min-width: $desktop) {
            width: 87.73%;
        }
    }

    &__content-img {
        width: 100%;
        margin-top: v(21);
        height: 100%;
        @media(min-width: 320px) and (max-width: $desktop) {
            margin-top: 0;
        }
    }

    .--small & {
        width: v(204);
        height: v(408);

        @media (min-width: $tablet) {
            width: v(204);
            height: v(408);
        }

        @media (min-width: $desktop) {
            width: v(204);
            height: v(408);
        }

        &__cover {
            position: relative;
            z-index: 2;
            width: 100%;
            height: 100%;
            filter: drop-shadow(0px 4px 16px rgba(25, 42, 51, 0.6));
        }

        &__content {
            position: absolute;
            top: v(16);
            left: 50%;
            z-index: 1;
            width: 87.73%;
            height: 92%;
            overflow: hidden;
            transform: translateX(-50%);
        }

        &__content-img {
            width: 100%;
            margin-top: v(15);
        }
    }
}
