$btn-box-shadow: 0px v(4) v(8) rgba($primary100, 0.25), 0px v(4) v(24) rgba($primary100, 0.6);
$btn-border-radius: v(32);

.btn {
    $btn-border-size: 4;

    display: inline-block;
    cursor: pointer;

    min-width: v(136);
    height: v(40 + $btn-border-size);
    line-height: v(40);
    box-sizing: border-box;
    box-shadow: $btn-box-shadow;

    border-radius: $btn-border-radius;
    border: v($btn-border-size) solid $primary90;

    background-size: cover;
    background-position: center;

    padding-left: v(30);
    padding-right: v(30);

    &.--icon {
        padding-left: v(11);
        padding-right: v(26);
    }

    &-icon, &-text {
        color: $accent30;
        text-shadow: 0px v(2) v(2) rgba(0, 0, 0, 0.5);
        font-size: v(16);
        font-size: v(16);
    }

    &-icon {
        display: inline-block;
        margin-right: v(14);
        width: v(24);
        height: 100%;

        text-align: center;
    }

    &-text {
        display: inline-block;
        height: 100%;

        font-family: $headerFontFamily;
        font-style: normal;
        font-weight: 500;

        text-align: center;
    }

    &.--primary {
        background-image: url('assets/img/btn2-bg-still3x.jpg');
        &:hover, &.--active {
            background-image: url('assets/img/btn2-bg-hover3x.jpg');

            .btn-text, .btn-icon {
                color: $white;
            }
        }
    }

    &.--secondary {
        background-image: url('assets/img/btn-bg-still3x.jpg');

        &:hover, &.--active {
            background-image: url('assets/img/bth-bg-active3x.jpg');

            .btn-text, .btn-icon {
                color: $white;
            }
        }
    }

    &.--big {
        line-height: v(48);
        height: v(48 + $btn-border-size);

        .btn-icon {
            width: v(32);
        }
        .btn-text, .btn-icon {
            font-size: v(20);
        }
    }

    &.--tab {
        box-shadow: none;

        .btn-text {
            color: $accent40;
        }

        &-left {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-right-width: 0;
        }
        &-center {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
        &-right {
            border-left-width: 0;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
}

.tabs {
    display: inline-block;
    box-shadow: $btn-box-shadow;
    border-radius: $btn-border-radius;
}