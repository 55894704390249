.team-head {
    padding-top: v(24);
    padding-bottom: v(24);
    color: $white;

    &__list {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;

        @media (min-width: $desktop) {
            margin-left: v(70);
            margin-right: v(70);
        }
    }

    &__item {
        width: v(408);
        margin-bottom: v(72);


        @media (min-width: $tablet) {
            margin-left: auto;
            margin-right: auto;
        }

        @media (min-width: $desktop) {
            margin-left: 0;
            margin-right: 0;
        }
    }

    &__item:last-of-type {
        margin-bottom: 0;
    }

    &__item-bage {
        // @media (min-width: $desktop) {
        //     margin-right: v(48);
        // }
    }

    .our-team__person-bage {
        margin: 0 auto;

        img {
            width: 102%;
            height: 102%;
            margin-left: -1%;
            margin-top: -1%;
            background: #261815;
        }
    }

    &__item-text {

        @media (min-width: $desktop) {
            // width: v(648);
        }
    }

    &__item-name {
        font-family: $headerFontFamily;
        font-size: v(24);
        line-height: v(24);
        text-align: center;
        color: $primary30;
        font-weight: 500;
    }

    &__item-position {
        font-family: $textFontFamily;
        font-style: normal;
        font-weight: 500;
        font-size: v(16);
        line-height: v(16);
        /* or 250% */

        text-align: center;
        text-transform: uppercase;

        /* Primary Dark 50 */

        color: $primary50;

        margin-bottom: v(24);
    }


    &__item-text-description {
        font-family: $textFontFamily;
        font-style: normal;
        font-weight: normal;
        font-size: v(16);
        line-height: v(24);
        /* or 150% */


        /* Primary Dark 30 */

        color: $primary30;
    }
}

.team-head__item-text-description {
    display: none;
}