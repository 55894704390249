.our-team {
    padding-top: v(24);
    padding-bottom: v(24);

    &__first-row {
        display: flex;
        justify-content: space-evenly;
        margin-bottom: v(40);

        @media (max-width: $mobile) {
            display: block !important;
        }
    }

    &__second-row {
        display: flex;
        justify-content: space-between;
        margin-bottom: v(32);

        @media (min-width: $mobile) {
            flex-wrap: wrap;
            justify-content: space-around;
            // display: block !important;
        }

        @media (min-width: $tablet) {
            flex-wrap: wrap;
            justify-content: space-around;
            // display: block !important;
        }
        @media (min-width: $desktop) {
            flex-wrap: wrap;
            justify-content: space-between;
            // display: block !important;
        }

        .our-team__person-bage {
            @media (max-width: $mobile) {
                // display: block !important;
            }

            @media (max-width: $tablet) {
                // flex-wrap: wrap;
                // display: block !important;
            }
        }
    }

    &__btn-meet {
        width: v(264);
        margin-left: auto;
        margin-right: auto;
    }

    &__person-bage {
        width: v(192);
    }

    &__person-bage-img {
        display: block;
        width: v(120);
        height: v(120);
        margin-right: auto;
        margin-bottom: v(16);
        margin-left: auto;
        border-radius: 50%;
        
        overflow: hidden;

        border: v(3) solid $primary60;
        border-width: v(3);
        
        img {
            width: 102%;
            height: 102%;
            margin-left: -1%;
            margin-top: -1%;
        }
    }

    &__person-bage-name {
        // text-transform: uppercase;
        font-weight: 600;
        font-size: v(24);
        line-height: v(24);
        text-align: center;
        color: $primary30;

        white-space: nowrap;

        &.--center {
            margin-left: 50%;
            transform: translateX(-50%);
            width: v(300);
        }

        @media (max-width: $mobile) {
            font-size: v(16);
            line-height: v(24);
        }
    }

    &__person-bage-position {
        font-size: v(16);
        line-height: v(24);
        text-align: center;
        color: $primary50;

        font-family: $textFontFamily;
        font-style: normal;
        font-weight: normal;
        text-transform: uppercase;

        white-space: nowrap;

        @media (max-width: $mobile) {
            font-size: v(16);
            line-height: v(24);
        }
    }

    &__person-bage--size-large {
        width: v(216);

        .our-team__person-bage-img {
            width: v(216);
            height: v(216);

            border-width: v(5);
        }

        // .our-team__person-bage-name {
        //     text-transform: none;
        //     font-weight: bold;
        //     font-size: v(24);
        //     line-height: v(32);

        //     @media (max-width: $mobile) {
        //         font-size: 24px;
        //         line-height: 32px;
        //     }
        // }

        // .our-team__person-bage-position {
        //     text-transform: uppercase;
        //     font-weight: 900;
        //     font-size: v(16);
        //     line-height: v(24);

        //     @media (max-width: $mobile) {
        //         font-size: 16px;
        //         line-height: 24px;
        //     }
        // }
    }
}