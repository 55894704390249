// stylelint-disable
* {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    outline: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent; // For some Androids
    tap-highlight-color: rgba(0, 0, 0, 0);//mozilla firefox 28 bug//Remove Gray Highlight When Tapping Links in Mobile Safari and Webkit
}

::before,
::after {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    outline: 0;
}

html {
    line-height: 1.15;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
    vertical-align: baseline;
    padding: 0;
    font-size: 100%;
    border: 0;
    background: transparent;
    margin: 0;
}

main,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
    content: none;
}

a {
    vertical-align: baseline;
    padding: 0;
    font-size: 100%;
    background: transparent;
    margin: 0;
}

ins {
    text-decoration: none;
    color: #000;
    background-color: #ff9;
}

mark {
    font-weight: bold;
    font-style: italic;
    color: #000;
    background-color: #ff9;
}

del {
    text-decoration: line-through;
}

abbr[title],
dfn[title] {
    border-bottom: 1px dotted;
    cursor: help;
}

table {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
}

hr {
    display: block;
    height: 1px;
    padding: 0;
    border: 0;
    border-top: 1px solid #eee;
    margin: 0;
}

input,
select {
    vertical-align: middle;
}

input {
    border: none;
}

button {
    padding: 0;
    font: inherit;
    color: inherit;
    border: none;
    background-color: transparent;
    cursor: pointer;
}

audio,
video {
    display: inline-block;
}

audio:not([controls]) {
    display: none;
    height: 0;
}

img {
    border-style: none;
}

svg:not(:root) {
    overflow: hidden;
}

ul li {
    list-style: none;
}

button,
input,
optgroup,
select,
textarea {
    margin: 0;
}

button,
input {
    overflow: visible;
}

button,
select {
    text-transform: none;
}

button {
    background-color: transparent;
    outline: none;
    cursor: pointer;
}

button,
html [type='button'],
[type='reset'],
[type='submit'] {
    -webkit-appearance: button;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
    border-style: none;
    padding: 0;
}

[type='search'] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
}

[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
}

[hidden] {
    display: none;
}

a {
    text-decoration: none;
    color: inherit;
}