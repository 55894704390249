.team-numbers {
    padding-top: v(24);
    padding-bottom: v(24);
    margin-bottom: v(80);
    color: $white;
    position: relative;

    &__title {
        font-family: $textFontFamily;
        font-style: normal;
        font-weight: 500;
        font-size: v(16);
        line-height: v(40);
        /* or 250% */
        text-align: center;
        text-transform: uppercase;

        color: $primary50;

        margin-bottom: v(30);

        @media (max-width: $mobile) {
            // font-size: 28px;
            // line-height: 40px;
        }
    }

    &__fact-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;

        @media (min-width: $desktop) {

        }
        @media (min-width: $tablet) {
            flex-wrap: nowrap;
        }
    }

    &__fact {
        width: v(216);
        text-align: center;

        @media (max-width: $tablet) {
            margin-bottom: v(24);
        }
    }

    &__fact-value {
        position: relative;
        font-family: $headerFontFamily;
        font-style: normal;
        font-weight: 900;
        font-size: v(56);
        line-height: v(80);
        /* or 143% */

        text-align: center;
        font-feature-settings: 'pnum' on, 'lnum' on;

        /* Primary Dark 30 */

        color: $primary30;

        @media (max-width: $mobile) {
            // font-size: 48px;
            // line-height: 64px;
        }
        z-index: 1;

        &::after {
            z-index: -1;
            content: ' ';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-image: url('assets/img/knot-small.svg');
            width: v(80);
            height: v(80);
            background-repeat: no-repeat;
            background-size: cover;
        }
    }

    &__fact-key {
        font-family: $headerFontFamily;
        font-style: normal;
        font-weight: bold;
        font-size: v(24);
        line-height: v(32);
        /* or 133% */

        text-align: center;

        /* Primary Dark 30 */

        color: $primary30;

        @media (max-width: $mobile) {
            // font-size: 28px;
            // line-height: 40px;
        }
    }

    &__fact-note {
        /* paragraph */

        font-family: $textFontFamily;
        font-style: normal;
        font-weight: normal;
        font-size: v(16);
        line-height: v(24);
        /* or 150% */

        text-align: center;

        /* Primary Dark 30 */

        color: $primary30;

        @media (max-width: $mobile) {
            // font-size: 16px;
            // line-height: 24px;
        }
    }


}