.slider {
    &__img {
        width: v(264);
        height: v(202);

        @media (min-width: $tablet) {
            width: v(168);
            height: v(128);
        }

        @media (min-width: $desktop) {
            width: v(312);
            height: v(240);
        }
    }

    &__img--left {
        display: none;

        @media (min-width: $tablet) {
            display: block;
        }
    }

    &__img--center {
        @media (min-width: $tablet) {
            margin-right: v(20);
            margin-left: v(20);
        }
    }

    &__img--right {
        display: none;

        @media (min-width: $tablet) {
            display: block;
        }
    }

    &__controls {
        display: flex;
        justify-content: space-between;
        width: v(56);
        height: v(8);
        margin-right: auto;
        margin-bottom: v(48);
        margin-left: auto;

        @media (min-width: $tablet) {
            display: none;
        }
    }

    &__pin {
        display: block;
        width: v(8);
        height: v(8);
        border: 2px solid $accent50;
        border-radius: 50%;

        &--active {
            background-color: $accent50;
        }


    }


}