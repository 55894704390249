.how-work {
    padding-top: v(0.1);
    padding-bottom: v(48);
    background-color: $primary100;
    position: relative;
    z-index: 1;

    &__backdrop {
        content: ' ';
        width: 100%;
        left: 0;
        right: 0;
        position: absolute;
        bottom: -0.5px;
        height: v(200);
    }

    &__title {
        margin-bottom: v(40);
        @media(min-width: 320px) and (max-width: 487px)  {
            margin-bottom: v(64);
            //left: 28%;
            //transform: translateX(-50%);
            //position: relative;
        }
    }

    &__subtitle {
        margin-top: v(64);
        margin-bottom: v(64);
        text-align: center;
        font-family: Alegreya Sans;
        font-style: normal;
        font-weight: bold;
        font-size: v(36);
        line-height: v(48);
        color: #fff;
        @media(min-width: 320px) and (max-width: 487px)  {
            margin-top: 0;
        }
        @media(min-width: 320px) and (max-width: 881px)  {
            font-size: v(20);
            line-height: v(32);
        }
        @media(min-width: 320px) and (max-width: 487px)  {
            margin-bottom: v(32);
        }
    }

    &__container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: v(20);
        line-height: v(24);
        color: #B8CCD9;
        padding-bottom: v(64);
        @media(min-width: 320px) and (max-width: 811px) {
            flex-wrap: wrap;
        }
    }

     &__text {
        margin-right: v(64);
        width: v(523);
         @media(min-width: $desktop) and (max-width: 1175px) {
             width: 408px;
             font-family: Source Sans Pro;
             font-style: normal;
             font-weight: normal;
             font-size: 20px;
             line-height: 24px;
         }
         @media(min-width: 811px) and (max-width: $desktop) {
             width: 302px;
             font-family: Source Sans Pro;
             font-style: normal;
             font-weight: normal;
             font-size: 14px;
             line-height: 24px;
         }
         @media(min-width: 320px) and (max-width: 811px)  {
             margin: 0 0 v(50);
             width: 100%;
             font-family: Source Sans Pro;
             font-style: normal;
             font-weight: normal;
             font-size: 14px;
             line-height: 24px;
         }
    }

    &__list-items {
        list-style: disc;
        padding-left: 30px;
        & li::before {
        content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
        color: #B8CCD9; /* Change the color */
        font-weight: bold; /* If you want it to be bold */
        display: inline-block; /* Needed to add space between the bullet and the text */
        width: 1em; /* Also needed for space (tweak if needed) */
        margin-left: -1em; /* Also needed for space (tweak if needed) */
    }
    }

    &__picture {
        //width: v(523);
        //height: v(400);
        width: 100%;
    }

    &__images-block {
        margin-bottom: v(32);

        .slider {
            display: flex;
            justify-content: center;
        }

        .slider__img {
            border: v(4) solid $primary70;
            border-radius: v(4);
        }
    }

    &__tabs {
        margin-bottom: v(40);
    }

    &__img {
        width: v(264);
        height: v(202);

        @media (min-width: $tablet) {
            width: v(168);
            height: v(128);
        }

        @media (min-width: $desktop) {
            width: v(312);
            height: v(240);
        }
    }

    &__img--left {
        display: none;

        @media (min-width: $tablet) {
            display: block;
        }
    }

    &__img--center {
        @media (min-width: $tablet) {
            margin-right: v(20);
            margin-left: v(20);
        }
    }

    &__img--right {
        display: none;

        @media (min-width: $tablet) {
            display: block;
        }
    }

    &__step-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin-bottom: v(32);

        @media (min-width: $tablet) {
            justify-content: space-around;
        }

        @media (min-width: $desktop) {
            justify-content: center;
            margin-bottom: v(24);
        }
    }

    &__step-list-item {
        position: relative;
        display: flex;
        align-items: center;
        height: v(80);
        margin-bottom: v(40);
        padding-left: v(72);

        @media (min-width: $tablet) {
            width: v(248);
        }

        @media (min-width: $desktop) {
            width: v(248);
            height: v(80);
            margin-right: v(40);
            margin-bottom: 0;
        }

        &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            display: block;
            width: v(80);
            height: v(80);
            transform: translate(0, -50%);
            background-image: url('assets/img/knot-small.svg');
            background-size: contain;
            background-repeat: no-repeat;
        }

        &:last-of-type {
            margin-right: 0;
        }
    }

    &__step-list-item-num {
        position: absolute;
        top: 45%;
        left: v(20);
        font-weight: 900;
        font-size: v(56);
        font-family: $headerFontFamily;
        color: $primary30;
        transform: translateY(-50%);

        width: v(40);
        text-align: center;
        line-height: v(80);
    }

    &__step-list-item-text {
        z-index: 1;
        font-weight: normal;
        font-size: v(20);
        line-height: v(24);
        font-family: $textFontFamily;
        color: $primary30;

        @media (min-width: $tablet) {
            width: v(176);
        }
    }

    &__end-text {
        display: block;
        margin-right: auto;
        margin-left: auto;
        font-weight: 600;
        font-size: v(24);
        line-height: v(28);
        text-align: center;
        color: $primary30;

        @media (min-width: $desktop) {
            width: v(744);
            font-size: v(28);
            line-height: v(32);
        }
    }

}

.how-work__images-block.owl-carousel {
    overflow: hidden;
    .owl-item {
        display: inline-block;
    }
}

p {
    margin-bottom: 16px;
}
