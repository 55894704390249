@import './../../../components/global/constants.scss';

@keyframes showBlock {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.feedback {
    padding-top: v(48);
    padding-bottom: v(72);
    position: relative;
    z-index: 1;
    color: $white;

    &__wrapper {
        display: flex;
    }

    &__left-column {
        width: v(504);
        margin-right: v(72)
    }

    &__right-column {
        width: v(504);
        position: relative;
    }

    &__title {
        margin-bottom: v(24);
        font-family: $textFontFamily;
        font-style: normal;
        font-weight: normal;
        font-size: v(16);
        text-transform: uppercase;
        color: $primary50;
    }

    &__text {
        display: block;
        font-family: $textFontFamily;
        min-height: v(55);
        font-size: v(16);
        line-height: v(28);
        margin-bottom: v(24);
        text-transform: uppercase;
        color: $primary50;
    }

    &__contact {
        position: relative;
        padding-left: v(58);
        font-size: v(16);
        line-height: v(24);
        margin-bottom: v(24);
        font-family: $textFontFamily;
        font-style: normal;
        color: $primary30;

        i {
            position: absolute;
            top: 50%;
            left: 0;
            display: block;
            width: v(34);
            height: v(24);
            font-size: v(20);
            line-height: v(28);
            text-align: center;
            color: $primary60;
            text-decoration: none;
            transform: translateY(-50%);
        }
    }

    &__contact:last-of-type {
        margin-bottom: 0;
    }

    &__contact-group {
        margin-bottom: v(72);
    }

    &__social-title {
        line-height: v(32);
        text-align: left;

        font-family: $textFontFamily;
        font-style: normal;
        font-weight: normal;
        font-size: v(16);

        text-transform: uppercase;

        color: $primary50;
    }

    &__social-list {
        display: flex;
        justify-content: left;

        margin-left: v(-16);
    }

    &__nav-item-link {
        padding: v(8);
        font-weight: 600;
        font-size: v(16);
        line-height: v(24);
        color: $accent60;
        text-decoration: none;

        &--active {
            color: $primary40;
            border-bottom: v(2) solid $accent60;
        }

        &:hover {
            color: $primary40;
        }
    }

    &__social {
        display: flex;

    }

    &__social-item-link {
        position: relative;
        display: block;
        width: v(68);
        height: v(48);
        margin-right: v(11);

        &:last-of-type {
            margin-right: 0;
        }

        i {
            position: absolute;
            top: 50%;
            left: 50%;
            display: block;
            width: v(28);
            height: v(28);
            font-size: v(20);
            line-height: v(28);
            text-align: center;
            color: $accent50;
            text-decoration: none;
            transform: translate3d(-50%, -50%, 0);
        }

        &:hover i {
            color: $primary40;
        }
    }

    &__input {
        width: 100%;
        position: relative;
        display: block;
        margin-bottom: v(16);
        padding: v(8);
        font-size: v(16);
        line-height: v(24);
        font-family: $textFontFamily;
        color: $primary30;
        background-color: $primary90a90;
        border: none;
        border-radius: 4px;
        padding-left: v(40);
        &::placeholder {
            color: $primary70;
        }

        &:focus {
            color: $white;
            border-color: $primary40;
        }

        &:placeholder-shown {
            border-color: $primary70;
        }
    }

    &__icon-wrapper {
        position: relative;
    }

    &__input-icon {
        top: v(12);
        left: v(12);
        width: v(18);
        height: v(18);
        text-align: center;
        line-height: v(18);
        position: absolute;
        color: $primary40;
        font-size: v(18);
        z-index: 1;
    }

    &__message {
        width: 100%;
        min-height: v(168);
    }

    &__btn-submit {
        width: v(168);
    }

    &__popup {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        align-items: center;
        justify-content: center;
        display: none;
        z-index: 5;
        &_opened {
            display: flex;
            animation: showBlock .2s linear forwards;
        }
    }
    &__popup-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: v(200);
        width: v(360);
        background: $primary100;
        border-radius: v(4);
        box-shadow: 0 v(4) v(8) rgba(0, 0, 0, 0.25);
        position: relative;
        box-sizing: border-box;
        padding: v(24) v(24);

    }

    &__popup-text {
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: v(16);
        line-height: v(24);
        text-align: center;
        margin-top: v(24);
        color: $primary50;
        display: none;
    }

    &__popup-text_contact.popup-vision {
        display: block;
    }

    &__popup-text_message.popup-vision {
        display: block;
    }

    &__popup-button.btn {
        min-width: auto;
        margin: auto auto 0;
    }
}

@media (min-width: $desktop) {
    .feedback-form__tg-btn {
        position: absolute;
        right: 0;
    }
}
