.our-product {
    padding-top: v(24);
    padding-bottom: v(24);
    padding-left: v(96);
    padding-right: v(96);

    &__title {
        font-family: $headerFontFamily;
        font-weight: bold;
        font-size: v(36);
        line-height: v(40);
        text-align: center;
        color: $white;
        margin-bottom: v(24);
        text-align: left;
    }

    &__wrapper {
        display: block;
        // justify-content: center;

        @media(min-width: $tablet) {
            display: flex;
        }

        @media(min-width: $desktop) {
            display: flex;
        }
    }

    &__slider {
        margin-right: v(48);
    }

    &__slider-pic-wrapper {
        margin-bottom: v(24);
    }

    &__slider-controls {
        display: flex;
        justify-content: space-between;
        width: v(144);
        height: v(24);
        margin-right: auto;
        margin-bottom: v(48);
        margin-left: auto;
    }

    &__slider-pin {
        display: block;
        width: v(8);
        height: v(8);
        border: 2px solid $accent50;
        border-radius: 50%;

        &--active {
            background-color: $accent50;
        }
    }

    &__description {
        display: inline-block;

        @media(min-width: $tablet) {
            width: v(576);
            margin-left: v(34);
            margin-top: v(24);
        }

        @media(min-width: $desktop) {
            width: v(576);
            margin-left: v(48);
            margin-top: v(48);
        }
    }

    &__description-text {
        width: v(576);
        font-size: v(24);
        line-height: v(32);
        margin-top: v(48);
        margin-bottom: v(48);

        /* Lead */
        font-family: $textFontFamily;
        font-style: normal;
        font-weight: normal;

        /* Primary Dark 30 */
        color: $primary30;
    }

    &__btn-learn-more {
        width: v(186);
    }
}

.our-product .hero {
    &__phones {
        display: none;
        @media (min-width: $desktop) {
            display: inline-block;
        }

        &--mobile {
            display: block;
            @media (min-width: $desktop) {
                display: none;
            }
        }

        position: relative;
        width: v(204);
        height: v(458);
        margin-left: 0;

        @media(min-width: $tablet) {
            width: v(408);
            height: v(458);
        }

        @media(min-width: $desktop) {
            width: v(408);
            height: v(458);
        }

        @media(max-width: $tablet) {
            margin-left: auto;
            margin-right: auto;
            // display: block;
        }
    }

    &__central-phone {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
    }

    &__left-phone {

        position: absolute;
        top: 0;
        left: 0;
        display: none;
        transform: scale(0.9);
        transform-origin: left center;
        filter: brightness(0.5);

        @media (min-width: $desktop) {
            display: block;
        }
    }

    &__right-phone {

        position: absolute;
        top: 0;
        right: 0;
        display: none;
        transform: scale(0.9);
        transform-origin: right center;
        filter: brightness(0.5);

        @media (min-width: $desktop) {
            display: block;
        }
    }

    &__slider-controls {
        position: absolute;
        bottom: v(-20);
        left: 50%;
        display: none;
        justify-content: space-between;
        width: v(56);
        height: v(8);
        margin-right: auto;
        margin-bottom: v(48);
        margin-left: auto;
        transform: translateX(-50%);

        @media (min-width: $tablet) {
            display: flex;
        }

        @media (min-width: $desktop) {
            display: none;
        }
    }

    &__slider-pin {
        display: block;
        width: v(8);
        height: v(8);
        border: 2px solid $accent50;
        border-radius: 50%;

        &--active {
            background-color: $accent50;
        }
    }
}

.hero__phones--mobile {
    .owl-carousel.phone__content {
        z-index: 10;
        border-radius: v(16);
    }
    .phone__content-img {
        width: v(176);
    }
    .owl-item {
        display: inline-block;
    }
}