.features {
    padding-bottom: v(24);
    // background-color: $primary90;
    position: relative;
    z-index: 1;

    &__owl-wrapper {
        overflow: hidden;
        margin-bottom: v(32);
        .owl-item {
            display: inline-block;
        }
    }

    &__partner-bage {
        width: v(320);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        @media(min-width: 812px) and (max-width: 881px)  {
            width: v(180);
        }
        @media(min-width: 408px) and (max-width: 811px)  {
            width: 158px;
        }
        @media(min-width: 320px) and (max-width: 600px)  {
            //margin-right: 64px;
        }
        @media(min-width: 320px) and (max-width: 407px)  {
            width: 158px;
        }
    }


    &__partner-bage-name {
        font-family: Alegreya Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 28px;
        line-height: 36px;
        color: #fff;
        @media(min-width: 320px) and (max-width: 811px)  {
            font-family: Source Sans Pro;
            font-style: normal;
            font-weight: normal;
            font-size: v(20);
            line-height: v(24);
            color: #fff;
        }
        //@media(min-width: 320px) and (max-width: 407px)  {
        //    text-align: center;
        //}
    }

    &__partner-bage-text {
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 32px;
        color: #B8CCD9;
        @media(min-width: 320px) and (max-width: 811px)  {
            font-size: v(14);
            line-height: v(24);
        }
        //@media(min-width: 320px) and (max-width: 407px)  {
        //    text-align: center;
        //}
    }

    &__partner-bage-img {
        width: v(319);
        height: v(192);
        @media(min-width: 812px) and (max-width: 881px)  {
            width: v(180);
        }
        @media(min-width: 408px) and (max-width: 811px)  {
            width: v(158);
        }
        @media(min-width: 320px) and (max-width: 407px)  {
            width: 100%;
        }
    }

    &__list {
        @media (min-width: $desktop) {
            padding-left: 144px;
            padding-right: 144px;
        }
    }

    &__subtitle {
        margin-top: v(36);
        margin-bottom: v(64);
        text-align: center;
        font-family: Alegreya Sans;
        font-style: normal;
        font-weight: bold;
        font-size: v(36);
        line-height: v(48);
        color: #fff;
        @media(min-width: 320px) and (max-width: 881px)  {
            font-size: v(20);
            line-height: v(32);
            margin-bottom: v(32);
        }
        //@media(min-width: 408px) and (max-width: 487px)  {
        //    margin-bottom: v(32);
        //}
        @media(min-width: 320px) and (max-width: 407px)  {
            margin-bottom: v(48);
        }
    }

    &__title {
        margin-bottom: v(32);
        @media(min-width: 320px) and (max-width: 487px)  {
            margin-bottom: v(64);
        }
    }

    &__item {
        position: relative;
        flex-direction: column;
        margin-bottom: v(72);

        @media (min-width: $tablet) {
            min-height: v(122);
            padding-left: v(192);
        }

        @media (min-width: $desktop) {
            min-height: v(192);
            padding-left: v(275);
        }
    }

    &__item-pic {
        display: block;
        width: v(168);
        height: v(122);
        margin-right: auto;
        margin-bottom: v(16);
        margin-left: auto;

        @media (min-width: $tablet) {
            position: absolute;
            top: 0;
            left: 0;
        }

        @media (min-width: $desktop) {
            width: v(264);
            height: v(192);
        }

    }

    &__item-title {
        margin-bottom: v(16);
        font-weight: bold;
        font-size: v(28);
        line-height: v(36);
        font-family: $headerFontFamily;
        text-align: center;
        color: $white;

        padding-top: v(24);

        @media (min-width: $tablet) {
            display: block;
            text-align: left;
        }
    }

    &__item-text {
        font-weight: 600;
        font-size: v(20);
        line-height: v(24);
        font-family: $textFontFamily;
        text-align: center;
        color: $primary30;

        @media (min-width: $tablet) {
            text-align: left;
        }

        @media (min-width: $desktop) {
            font-weight: 400;
            font-size: v(24);
            line-height: v(32);
        }
    }

    &__btn-wrap {
        position: relative;
        z-index: 1;
    }

    &__btn-try {
        width: v(216);
        margin-right: auto;
        margin-left: auto;
    }
    &__mobile-flex {
        display: none;
        @media(min-width: 408px) and (max-width: 600px)  {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            flex-wrap: wrap;
            padding-bottom: v(50);
        }
        @media(min-width: 320px) and (max-width: 407px)  {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            padding-bottom: v(50);
            align-items: center;
        }

    }
}

.reqdemo-modal {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: rgba(0,0,0,0.1);
    z-index: 9999;

    &.--closed {
        z-index: -1;
        display: none;
    }

    &__wnd {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        width: v(360);
        background: $primary100;
        border-radius: v(4);
        box-shadow: 0px v(4) v(8) rgba(0, 0, 0, 0.25);

        padding-bottom: v(24);
        padding-left: v(24);
        padding-right: v(24);
    }

    &__close-btn {
        cursor: pointer;
        position: absolute;
        color: $accent50;
        font-size: v(24);
        top: v(10);
        right: v(12);
    }

    &__img {
        margin-top: v(24);
        margin-left: auto;
        margin-right: auto;
        display: block;
    }

    h2 {
        font-family: $headerFontFamily;
        font-weight: bold;
        font-size: v(24);
        text-align: center;
        color: $primary50;
        line-height: v(42);
    }

    p {
        font-family: $textFontFamily;
        font-size: v(16);
        line-height: v(24);
        text-align: center;
        color: $primary50;
        margin-left: v(24);
        margin-right: v(24);
        margin-bottom: v(18);
    }

    &__btn-ok {
        margin-bottom: v(24);
    }

    // -----------

    &__input-wrap {
        position: relative;
        width: 100%;
        box-sizing: border-box;
        margin-bottom: v(24);
    }

    &__input {
        position: relative;
        display: block;
        width: 100%;
        margin-bottom: v(32);
        padding: v(8) v(8) v(8) v(40);
        font-size: v(16);
        line-height: v(24);
        font-family: $textFontFamily;
        color: $primary30;
        background-color: $primary90a90;
        // border: 1px solid $primary40;
        border-radius: v(4);

        @media (min-width: $desktop) {
            margin-bottom: 0;
        }

        &::placeholder {
            color: $primary70;
        }

        &:focus {
            color: $white;
            border-color: $primary40;
        }

        &:placeholder-shown {
            border-color: $primary70;
        }
    }

    &__input-icon {
        position: absolute;
        top: 50%;
        left: v(12);
        width: v(18);
        height: v(18);
        transform: translateY(-60%);
        color: $primary40;
        font-size: v(18);
    }

    &__input:focus + .contact__input-icon {
        color: $primary40;
    }


}


.features__owl-wrapper.owl-carousel {
    @media(min-width: 320px) and (max-width: 600px)  {
        display: none;
    }
}
