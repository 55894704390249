$white: #ffffff;

$primary10: #EDF6FA;
$primary20: #DAEAF2;
$primary30: #B8CCD9;
$primary40: #8FA7B2;
$primary50: #738C99;
$primary60: #597380;
$primary70: #425A66;
$primary80: #364D59;
$primary90: #2A414C;
$primary100: #192A33;

$primary90a90: rgba(42, 65, 76, 0.9);

$accent10: #FAF6F2;
$accent20: #F2E9DF;
$accent30: #D9CCBF;
$accent40: #B2A597;
$accent50: #998A7A;
$accent60: #807366;
$accent70: #66594C;
$accent80: #594C3E;
$accent90: #4C3F31;
$accent100: #33291F;

$mobile: 425px;
$tablet: 486px;
$desktop: 882px;
$desktop-full: 1024px;


$headerFontFamily: "Alegreya Sans", Helvetica, Arial, sans-serif;
$textFontFamily: "Source Sans Pro", Helvetica, Arial, sans-serif;
