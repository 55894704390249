.team-founders {
    padding-top: v(24);
    padding-bottom: v(48);
    color: $white;

    &__list {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__item {
        display: flex;
        margin-bottom: v(72);
        flex-wrap: wrap;
        justify-content: center;

        @media (min-width: $desktop) {

        }

    }

    &__item:last-of-type {
        margin-bottom: 0;
    }

    &__item-bage {

        @media (min-width: $desktop) {
            margin-right: v(48);
        }
    }

    &__item-text {


        @media (min-width: $desktop) {
            width: v(648);
        }
    }

    &__item-text-cite {
        position: relative;
        margin-bottom: v(24);
        padding: v(24);
        font-size: v(16);
        line-height: v(24);
        background-color: $primary30;

        font-family: $headerFontFamily;
        font-style: normal;
        font-weight: 600;
        color: $primary100;

        border-radius: v(5);

        margin-top: v(24);
        @media (min-width: $mobile) {
            margin-top: v(24);
        }

        @media (min-width: $tablet) {
            margin-top: v(24);
        }

        @media (min-width: $desktop) {
            margin-top: 0;
        }

        &::after {
            content: '';
            position: absolute;
            bottom: v(24);
            left: v(-24);
            width: v(24);
            height: v(24);
            border-style: solid;
            border-width: v(12) v(12) v(12) v(12);
            border-color: transparent $primary30 $primary30 transparent;

            // mobile
            left: 50%;
            transform: translateX(-50%);
            top: v(-24);
            border-color: transparent transparent $primary30 transparent;

            @media (min-width: $tablet) {
                // bottom: v(24);
                left: 50%;
                transform: translateX(-50%);
                top: v(-24);
                border-color: transparent transparent $primary30 transparent;
            }

            @media (min-width: $desktop) {
                bottom: v(24);
                left: v(-24);
                transform: none;
                top: initial;
                border-color: transparent $primary30 $primary30 transparent;
            }
        }
    }

    &__item-text-description {
        font-size: v(16);
        line-height: v(24);

        font-family: $textFontFamily;
        font-style: normal;
        font-weight: normal;
        /* or 150% */
        color: $primary30;

        @media (max-width: $mobile) {
            // font-size: 16px;
            // line-height: 24px;
        }
    }

}