.section-title {
    margin-bottom: v(48);
    font-family: $textFontFamily;
    font-style: normal;
    font-weight: normal;
    font-size: v(16);
    line-height: v(40);
    text-align: center;
    //text-transform: capitalize;
    color: $primary40;
    @media(min-width: 320px) and (max-width: $tablet)  {
        //display: none;
    }
}
