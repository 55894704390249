.hero-product {
    .hero-base__backdrop {
        background: linear-gradient(to bottom, rgba($primary100, 0) 0%, $primary100 100%);
    }

    &__text {
        text-align: left;

        @media (min-width: $tablet) {
            text-align: left;
        }
    }

    &__button-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__lead {
        margin-bottom: v(32);
        font-size: v(16);
        line-height: v(24);
        font-family: $textFontFamily;
        color: $primary30;

        @media (min-width: $tablet) {
            font-size: v(24);
            line-height: v(32);
        }
        @media(min-width: 812px) and (max-width: $desktop) {
            font-size: v(20);
            line-height: v(32);
            margin-top: v(32);
        }
        @media(min-width: $tablet) and (max-width: 811px)  {
            font-size: v(32);
            line-height: v(48);
            margin-top: v(32);
            margin-bottom: v(32);
        }
        @media(min-width: 320px) and (max-width: $tablet)  {
            font-size: v(20);
            line-height: v(32);
            margin-top: v(16);
            margin-bottom: v(16);
        }

    }

    &__btn-learn {
        width: v(184);
        margin-right: auto;
        margin-bottom: v(52);
        margin-left: auto;
        font-size: v(20);
        line-height: v(24);

        @media(min-width: $tablet) {
            margin-right: 0;
            margin-left: 0;
        }
    }

    &__phones {
        position: relative;
        width: 100%;
        height: v(471);

        @media(min-width: $tablet) {
            width: v(231);
            height: v(471);
            margin-left: auto;
        }


        @media(min-width: $desktop) {
            width: v(475);
            height: v(471);
        }

        @media(min-width: $desktop) and (max-width: $desktop-full) {
            width: 475px;
            height: 471px;
            margin-left: auto;
        }

        @media(min-width: 812px) and (max-width: $desktop) {
            height: 278px;
            width: 299px;
        }

        @media(min-width: $tablet) and (max-width: 811px) {
            display: inline-block;
            order: 1;
            margin: auto;
            height: 329px;
            width: 353px;
        }

        @media(min-width: 320px) and (max-width: $tablet)  {
            display: inline-block;
            order: 1;
            margin: auto;
            height: 250px;
            width: 270px;
        }

        //display: none;
        //@media (min-width: 811px) {
        //    display: inline-block;
        //}
        //
        &--mobile {
            //display: block;
            display: none;
            @media (min-width: 811px) {
                display: none;
            }
        }
    }

    &__central-phone {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
    }

    &__left-phone {

        position: absolute;
        top: 0;
        left: 0;
        //display: none;
        transform: scale(0.9);
        transform-origin: left center;
        filter: brightness(0.5);

        @media (min-width: 811px) {
            display: block;
        }
    }

    &__right-phone {

        position: absolute;
        top: 0;
        right: 0;
        //display: none;
        transform: scale(0.9);
        transform-origin: right center;
        filter: brightness(0.5);

        @media (min-width: 811px) {
            display: block;
        }
    }

    &__slider-controls {
        position: absolute;
        bottom: v(-20);
        left: 50%;
        display: none;
        justify-content: space-between;
        width: v(56);
        height: v(8);
        margin-right: auto;
        margin-bottom: v(48);
        margin-left: auto;
        transform: translateX(-50%);

        @media (min-width: $tablet) {
            display: flex;
        }

        @media (min-width: $desktop) {
            display: none;
        }
    }

    &__slider-pin {
        display: block;
        width: v(8);
        height: v(8);
        border: 2px solid $accent50;
        border-radius: 50%;

        &--active {
            background-color: $accent50;
        }


    }
}

.hero-product__phones--mobile {
    .owl-carousel.phone__content {
        z-index: 10;
        border-radius: v(16);
    }
    /*.phone__content-img {
        width: v(176);
    }*/
    .owl-item {
        display: inline-block;
    }
}
