.integration {
    padding-bottom: v(60);
    // background: linear-gradient(to bottom, $primary90 0%, $primary90 20%, $primary100);

    .owl-item {
        display: inline-block;
    }

    &__title {
        margin-bottom: v(48);

        @media(min-width: 320px) and (max-width: 487px)  {
            margin-bottom: v(64);
        }
    }

    &__list {
        // display: flex;
        // flex-direction: column;
        // justify-content: center;
        // align-items: center;


        // @media (min-width: $tablet) {
        //     display: flex;
        //     flex-direction: row;
        //     justify-content: center;
        // }
    }

    &__item {
        // display: flex;
        // flex-direction: column;
        // justify-content: flex-end;
        // align-items: center;
        width: v(168);
        // height: v(107);
        text-align: center;

        @media (min-width: $tablet) {
            width: v(264);
            // height: v(168);
            margin-right: v(24);
        }
    }

    &__item:last-of-type {
        margin-right: 0;
        margin-bottom: 0;
    }

    &__item-logo {
        &--luckme {
            width: v(78);
            height: v(61);

            @media(min-width: $tablet) {
                width: v(123);
                height: v(97);
            }
        }

        &--liga {
            width: v(137);
            height: v(35);

            @media(min-width: $tablet) {
                width: v(215);
                height: v(56);
            }
        }
    }

    &__item-link {
        display: block;
        padding-top: v(3);
        padding-bottom: v(3);

        font-size: v(16);
        line-height: v(24);
        font-family: $textFontFamily;
        color: $primary60;
        text-decoration: none;

        @media (min-width: $tablet) {
            padding-top: v(20);
            padding-bottom: v(20);
        }

        display: none;
    }

}
