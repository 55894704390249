.footer {
    // outline: 1px dashed green;
    display: flex;
    flex-wrap: wrap;
    justify-content: center; //space-between;
    padding-top: v(32);
    padding-bottom: v(24);

    &__copyright {
        font-weight: normal;
        font-size: v(12);
        line-height: v(24);
        font-family: $textFontFamily;
        color: $primary40;
    }

    &__nav {
        display: flex;
        justify-content: space-between;
        order: -1;
        width: v(360);
        margin-right: auto;
        margin-bottom: v(32);
        margin-left: auto;

        @media (min-width: $tablet) {
            justify-content: space-evenly;
            width: 100%;
        }

        @media (min-width: $desktop) {
            justify-content: space-between;
            order: 0;
            width: v(504);
        }
    }

    &__nav-item-link {
        padding: v(8);
        font-weight: 600;
        font-size: v(16);
        line-height: v(24);
        color: $accent60;
        text-decoration: none;

        &--active {
            color: $primary40;
            border-bottom: v(2) solid $accent60;
        }

        &:hover {
            color: $primary40;
        }
    }

    &__social {
        display: flex;

    }

    // &__social-item {

    // }

    &__social-item-link {
        position: relative;
        display: block;
        width: v(48);
        height: v(24);
        margin-right: v(8);

        &:last-of-type {
            margin-right: 0;
        }

        i {
            position: absolute;
            top: 50%;
            left: 50%;
            display: block;
            width: v(28);
            height: v(28);
            font-size: v(20);
            line-height: v(28);
            text-align: center;
            color: $accent40;
            text-decoration: none;
            transform: translate3d(-50%, -50%, 0);
        }

        &:hover i {
            color: $primary40;
        }
    }
}
