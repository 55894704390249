.team-staff {
    padding-top: v(24);
    padding-bottom: v(24);
    color: $white;

    .our-team__person-bage {
        margin: 0 auto;

        img {
            width: 102%;
            height: 102%;
            margin-left: -1%;
            margin-top: -1%;
            background: #261815;
        }
    }
    
    &__person-bage {
        width: v(264);
        margin-bottom: v(48);
    }

    &__person-bage-img {
        display: block;
        width: v(120);
        margin-right: auto;
        margin-bottom: v(16);
        margin-left: auto;
        border-radius: 50%;
    }

    &__person-bage-name {
        font-family: $headerFontFamily;
        font-style: normal;
        font-weight: 500;
        font-size: v(24);
        line-height: v(32);
        /* or 133% */
        text-align: center;

        /* Primary Dark 30 */

        color: $primary30;

        @media (max-width: $mobile) {
            font-size: 16px;
            line-height: 24px;
        }
    }

    &__person-bage-position {
        font-family: $textFontFamily;
        font-style: normal;
        font-weight: normal;
        font-size: v(16);
        line-height: v(16);
        /* or 250% */

        text-align: center;
        text-transform: uppercase;

        /* Primary Dark 50 */

        color: $primary50;

        margin-bottom: v(8);

        @media (max-width: $mobile) {
            // font-size: 16px;
            // line-height: 24px;
        }
    }

    &__person-bage-note {
        font-family: $textFontFamily;
        font-style: normal;
        font-weight: normal;
        font-size: v(12);
        line-height: v(20);
        /* or 167% */

        text-align: center;

        /* Primary Dark 30 */

        color: $primary30;

    }

    &__person-bage--size-medium {
        width: v(312);

        .team-staff__person-bage-img {
            width: v(168);
        }

        .team-staff__person-bage-name {
            text-transform: none;
            // font-weight: bold;
            font-size: v(24);
            line-height: v(24);

            @media (max-width: $mobile) {
                // font-size: 24px;
                // line-height: 32px;
            }
        }

        .team-staff__person-bage-position {
            font-family: $textFontFamily;
            font-style: normal;
            font-weight: normal;
            font-size: v(16);
            line-height: v(16);
            /* or 250% */

            text-align: center;
            text-transform: uppercase;

            /* Primary Dark 50 */

            color: $primary50;

            margin-bottom: v(8);

            @media (max-width: $mobile) {
                // font-size: 16px;
                // line-height: 24px;
            }
        }
    }

    &__first-row {
        display: flex;
        justify-content: center;
        margin-bottom: v(64);
        flex-wrap: wrap;

        .team-staff__person-bage {
            margin-right: 0;
        }

        @media (min-width: $desktop) {
            .team-staff__person-bage {
                margin-right: v(48);
            }

            .team-staff__person-bage:last-of-type {
                margin-right: v(48);
            }
        }

    }

    &__second-row {
        display: flex;
        justify-content: center;
        margin-bottom: v(32);
        flex-wrap: wrap;

        .team-staff__person-bage {
            margin-right: 0;
            width: v(264);
        }

        .our-team__person-bage {
            width: v(120);
            height: v(120);
            margin-bottom: v(16);
        }

        @media (min-width: $desktop) {
            .team-staff__person-bage {
                // margin-right: v(24);
            }

            .team-staff__person-bage:last-of-type {
                margin-right: v(24);
            }
        }

    }

    &__third-row {
        display: flex;
        justify-content: center;
        margin-bottom: v(48);
        flex-wrap: wrap;

        .team-staff__person-bage {
            margin-right: 0;
        }

        @media (min-width: $desktop) {
            .team-staff__person-bage {
                margin-right: v(24);
            }

            .team-staff__person-bage:last-of-type {
                margin-right: v(24);
            }
        }
    }


}


.team-staff__person-bage-note {
    display: none;
}