.hero-base {
    position: relative;
    min-height: v(696);
    padding-bottom: v(1);
    background-position: center bottom;
    background-size: cover;
    @media(min-width: 812px) and (max-width: $desktop) {
        min-height: v(434);
    }
    @media(min-width: 320px) and (max-width: $tablet) {
        padding-bottom: v(60);
    }

    &.--contain {
        background-position: center 17%;
    }

    &.--small-height {
        min-height: v(384);
    }

    &__backdrop {
        content: ' ';
        width: 100%;
        left: 0;
        right: 0;
        position: absolute;
        bottom: -0.5px;
        background: linear-gradient(to bottom, rgba($primary100, 0) 0%, $primary100 100%);
        height: v(200);
    }

    &__scrolldown-pointer {
        z-index: 1;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);

        display: block;
        width: v(40);
        margin-right: auto;
        margin-left: auto;

        @media(min-width: $tablet) {
            display: block;
        }

        @media(min-width: $desktop) {
            display: block;
        }
        //@media(min-width: 320px) and (max-width: $tablet)  {
        //    left: 30%;
        //    transform: translateX(-50%);
        //}
    }
}
