@import "./constants.scss";

$container-mobile-width: v(360);
$container-tablet-width: v(552);
$container-desktop-width: v(1128);

.container {
    max-width: $container-mobile-width;
    margin-right: auto;
    margin-left: auto;

    @media (min-width: $tablet) {
        max-width: $container-tablet-width;
    }

    @media (min-width: $desktop) {
        max-width: $container-desktop-width;
    }
    @media(min-width: 812px) and (max-width: $desktop) {
        max-width: v(668);
    }
}

body {
    font-family: $textFontFamily;
    background-color: $primary80;
}

.visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    border: 0;
    clip: rect(0 0 0 0);
}

.text-align-center {
    position: relative;
    text-align: center;
}

.ru-big1 {
    font-size: v(80);
}
