 .guide{
     &__steps{
         display: flex;
         flex-direction: column;
         align-items: center;
         grid-gap: 3.33rem;
         margin: 2.5rem 0 5rem;
     }

     &__header{
         width: unset;
     }

     &__num{
        font-weight: 900;
        font-size: 2.33333rem;
        font-family: Alegreya Sans,Helvetica,Arial,sans-serif;
        color: #b8ccd9;
        width: 1.66667rem;
        text-align: center;
        line-height: 3.33333rem;
        margin: 0 .833rem 10px 0;
     }

     &__img{
         width: 12.66rem;
     }

     &__step{
         width: 74.5vw;
         display: flex;
         grid-gap: 1.5rem;
         align-items: center;
         justify-content: center;
         overflow: visible;
         margin: 0 4.3rem 0 0;
     }

     /*&__step:nth-of-type(1){
        margin: 0 0 0 6rem;
     }

     &__step:nth-of-type(2){
        margin: 0 6rem 0 0;
     }

     &__step:nth-of-type(3){
        margin: 0 1rem 0 0;
     }

     &__step:nth-of-type(4){
        margin: 0 0 0 1.5rem;
     }

     &__step:nth-of-type(5){
        margin: 0 2.5rem 0 0;
     }*/



     &__step:nth-of-type(2n){
         flex-direction: row-reverse;
         margin: 0 0 0 4.3rem;
     }

     &__step:nth-of-type(2n) .guide__text{
        text-align: left;
    }

     &__content{
         display: flex;
         flex-direction: column;
         grid-gap: 1.5rem;
     }

     &__count{
        font-family: Alegreya Sans,Helvetica,Arial,sans-serif;
        font-weight: 700;
        line-height: 1.5rem;
        font-size: 1.166rem;
        color: #b8ccd9;
     }

     &__text{
         font-family: Source Sans Pro, sans-serif;
         font-weight: 400;
         font-size: .666rem;
         line-height: 1rem;
         color: #B8CCD9;
         width: 16.96rem;
         text-align: right;

         &_link{
             color: #D9CCBF;
         }
     }

     &__knotCount{
         display: flex;
         align-items: center;
         grid-gap: .2rem;
         background-image: url('/assets/img/knot-small.svg');
         background-repeat: no-repeat;
         background-position: right;
         background-size: contain;
         justify-content: flex-end;
         height: 3.33333rem;

         &_reverse{
            flex-direction: row-reverse;
            background-position: left;
            grid-gap: .4rem;
        }

        &_reverse .guide__num{
            margin: 0 0 10px .833rem;
        }


     }

 }

 @media screen and (max-width: 880px){
    .guide{
        &__steps{
            position: relative;
        }

        &__step{
            flex-direction: column;
            margin: 0!important;
            align-items: flex-start;
            width: unset;
        }

        &__num{
            margin: 0 0 10px .833rem;
        }

        &__content{
            max-width: 13.5rem;
        }

        &__knotCount{
            background-position: left;
            flex-direction: row-reverse;

            &_reverse{
                background-position: right;
                flex-direction: row;
            }

            &_reverse .guide__num{
                margin: 0 .833rem 10px 0;
            }
        }

        &__step:nth-of-type(2n){
            flex-direction: column;
            align-items: flex-end;
        }

        &__step:nth-of-type(2n) .guide__content{
            margin: 0 -1.166rem 0 0;
        }

        &__step:nth-of-type(2n) .guide__text{
            text-align: right;
        }

        &__step .guide__content{
            margin: 0 0 0 -1.166rem;
        }

        &__img{
            margin-left: auto;
            margin-right: auto;
        }

        &__header{
            width: unset;
        }

        &__text{
            width: unset;
        }
    }
 }