.hero-big-image {
    &__content {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: v(30);
        @media(min-width: $desktop) and (max-width: $desktop-full) {
            height: v(716);
            margin-top: v(40);
        }
        @media(min-width: 812px) and (max-width: $desktop) {
            height: v(380);
            margin-top: 0;
        }
        @media(min-width: 600px) and (max-width: 811px)  {
            min-height: v(840);
        }
        @media(min-width: 487px) and (max-width: 599px)  {
            min-height: v(940);
        }
    }

    &__text {
        z-index: 1;
        position: relative;
        @media (min-width: $tablet) {
            width: 100%;
        }

        @media (min-width: $desktop) {
            width: v(600);
            margin-left: v(24);
        }

        @media(min-width: $desktop) and (max-width: $desktop-full) {
            width: v(393);
        }
        @media(min-width: 812px) and (max-width: $desktop) {
            width: v(357);
        }
        @media(min-width: 320px) and (max-width: 811px)  {
            width: 100%;
            order: 2;
        }
        //@media(min-width: 320px) and ($tablet)  {
        //    width: 127px;
        //    height: 240px;
        //}
        @media(min-width: 320px) and (max-width: $desktop-full)  {
            .hero__button-demo-link {
                width: auto;
                white-space: nowrap;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                .btn-text {
                    height: auto;
                    line-height: v(24);
                }
                .btn-icon {
                    height: auto;
                }
            }
        }

    }

    &__title {
        font-weight: bold;
        font-family: $headerFontFamily;
        color: $white;

        text-shadow: v(0) v(4) v(24) rgba(25, 42, 51, 0.6), v(0) v(4) v(8) rgba(25, 42, 51, 0.25);

        // mobile
        font-size: v(36);

        @media (min-width: $tablet) {
            font-size: v(36);
            line-height: v(48);
        }

        @media (min-width: $desktop) {
            margin-top: v(72);
            margin-bottom: v(24);
            font-size: v(36);
            line-height: v(48);
            width: v(584);
        }
        @media(min-width: $desktop) and (max-width: $desktop-full) {
            width: v(393);
        }
        @media(min-width: 812px) and (max-width: $desktop) {
            width: v(357);
            font-size: v(20);
            line-height: v(32);
        }
        @media(min-width: $tablet) and (max-width: 811px)  {
            font-size: v(32);
            line-height: v(48);
        }
        @media(min-width: 320px) and (max-width: $tablet)  {
            font-size: v(20);
            line-height: v(32);
        }
    }
}
