.top-bar-padding {
    height: v(144);
    @media(min-width: 320px) and (max-width: $desktop) {
        width: v(668);
        height: v(120);
    }
}

.top-bar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: v(52);
    padding-top: v(24);
    color: $accent60;

    position: fixed;
    top: 0;
    z-index: 99;

    width: $container-mobile-width;

    @media (min-width: $tablet) {
        width: $container-tablet-width;
    }

    @media (min-width: $desktop) {
        width: $container-desktop-width;
    }
    @media(min-width: 812px) and (max-width: $desktop) {
        width: v(668);
    }

    &::after {
        content: ' ';
        z-index: 0;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 0;
        width: 100vw;
        height: v(144);
        background: linear-gradient(180deg, #0D151A 35%, rgba(#2A414C, 0) 100%);
        @media(min-width: 320px) and (max-width: $desktop) {
            height: v(120);
        }
    }

    &__logo,
    &__menu-btn,
    &__nav {
        z-index: 1;
    }

    //&__logo {
    //    width: v(152);
    //    height: v(48);
    //}

    &__logo-img {
        width: v(150);
    }

    &__menu-btn {
        width: v(22);
        height: v(17);
        margin-left: auto;

        @media (min-width: $desktop) {
            display: none;
        }
    }

    &__menu-icon {
        transform: translateY(v(-5));
        font-size: v(24);
    }

    &__nav {
        display: none;

        @media (min-width: $desktop) {
            display: block;
            margin-left: auto;
        }
    }

    &__nav-list {
        display: flex;
    }

    &__nav-item {
        margin-right: v(14);
        padding: v(8);

        // still
        color: $accent30;

        &:last-of-type {
            margin-right: 0;
        }

        &--active {
            color: $primary40;
            border-bottom: v(2) solid $accent60;
        }

        &:hover {
            color: $accent40;
        }

        &--has-submenu {
            position: relative;
            padding-right: v(20);
            color: $primary60;

            &:after {
                content: "\f078";
                position: absolute;
                top: 50%;
                right: 0;
                font-family: 'FA', sans-serif;
                color: $accent40;
                transform: translateY(-50%);

            }
        }
    }

    &__nav-item-link {
        font-family: $headerFontFamily;
        font-weight: 500;
        font-size: v(16);
        line-height: v(24);
        color: inherit;
        text-decoration: none;
        font-style: normal;

        &.--en {
            margin-right: v(8);
        }

        &.--ru {
            margin-right: v(8);
        }

        &.--lang {
            cursor: pointer;
            color: $primary50;

            &:hover,
            &.--active {
                color: $primary30;
            }
        }
    }
}

.top-bar-mobile {
    position: fixed;
    z-index: 999;

    width: v(288);
    height: 100vh;
    top: 0;

    background: $primary100;

    right: 0%;
    opacity: 1;
    transition-duration: 0.2s;
    transition-property: opacity, right;

    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.6), 0px 4px 8px rgba(0, 0, 0, 0.25);

    &::before {
        position: absolute;
        content: ' ';
        top: 0;
        bottom: 0;
        width: 100vw;
        right: 100%;
        background: rgba(0, 0, 0, 0.2);
    }

    &--closed {
        right: -110%;
        opacity: 0;

        &::before {
            display: none;
        }
    }

    .top-bar-mobile__close {
        position: absolute;

        width: v(24);
        height: v(32);
        top: v(32);
        right: v(32);

        font-size: v(30);
    }

    &__nav-list {
        position: absolute;
        top: v(120);
        left: v(24);
    }

    &__nav-item {
        font-family: $headerFontFamily;
        font-style: normal;
        font-weight: normal;
        font-size: v(24);
        line-height: v(33);
        color: $accent60;
        text-align: left;
        margin-bottom: v(38);

        a {
            color: inherit;
            text-decoration: none;
        }

        &--active {
            color: $primary40;
            a {
                border-bottom: v(2) solid $accent60;

            }
        }
    }

    &__lang-menu {
        position: absolute;
        top: v(32);
        right: v(90);

        .--en {
            margin-right: v(16);
        }

        .--ru {
            margin-right: v(16);
        }

        .--lang {
            cursor: pointer;
            color: $primary50;

            &:hover,
            &.--active {
                color: $primary30;
            }
        }
    }
}
