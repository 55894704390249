// @import './components/global/constants.scss';

body.product {
    background-color: $primary100;
}

section {
    overflow: hidden;
}

@media (max-width: $mobile + 60px) {
    .feedback__wrapper {
        display: block;
    }

    .feedback__left-column,
    .feedback__right-column {
        width: 100%;
    }
}
